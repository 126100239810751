import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HeroContainer, OurButton, TitleText } from './Shared';
import MastbottomPicture from '../assets/mac-notebook-2.jpg';

function Mastbottom({ scrollToElement }) {
  return (
    <HeroContainer backgroundImage={MastbottomPicture}>
      <Container className="h-100 d-flex align-items-end">
        <Row id="mastbottom-row" className="text-center mb-5" style={{ width: 'inherit' }}>
          <Col lg={12} className="header-text">
            <TitleText className="text-white text-left">we are</TitleText>
            <TitleText className="text-left text-secondary">mintqore</TitleText>
            <TitleText className="text-white text-left">
              we do
              {' '}
              <span className="text-secondary">more</span>
            </TitleText>
          </Col>

          <Col lg={12}>
            <OurButton size="lg" onClick={() => scrollToElement('top')} className="btn-secondary">
              back to top
            </OurButton>
          </Col>
        </Row>
      </Container>
    </HeroContainer>
  );
}

Mastbottom.propTypes = {
  scrollToElement: PropTypes.func.isRequired
};

export default Mastbottom;
