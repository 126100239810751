import React from 'react';
import { faMobile, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  Container, Row, Col, Button
} from 'react-bootstrap';
import styled from 'styled-components';
import { BrandIcon, Divider } from './Shared';

const ContactContainer = styled.section`
    padding: 8rem 0;
    background-color: #6c757d;
`;

function Contact() {
  return (
    <ContactContainer className="text-white" id="contact">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h2 className="mt-0" style={{ fontSize: '2.5rem' }}>Interested?</h2>
            <Divider className="my-4" style={{ borderColor: 'rgb(157, 25, 63)' }} />
            <p className="mb-5">
              Haben wir Ihr Interesse geweckt? Bitte zögern Sie nicht, uns zu kontaktieren.
            </p>
          </Col>
        </Row>

        <Row>
          {/*
          <Col lg={4} className="ml-auto text-center">
            <BrandIcon icon={faMobile} size="3x" />
            <div>+41 78 111 11 11</div>
          </Col>
          */}
          <Col className="mr-auto text-center">
            <BrandIcon icon={faEnvelope} size="3x" />
            <Button
              style={{ padding: '0', textDecoration: 'none', color: 'inherit' }}
              variant="link"
              className="d-block"
              href="mailto:marwan@mintqore.com"
            >
              info@mintqore.com
            </Button>
          </Col>
        </Row>
      </Container>
    </ContactContainer>
  );
}

export default Contact;
