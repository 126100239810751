import React from 'react';
import Layout from '../Layout';
import SEO from '../SEO';
import Hero from '../components/Hero';
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import Mastbottom from '../components/Mastbottom';

function IndexPage() {
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    element.scrollIntoView();
  }

  return (
    <Layout scrollToElement={scrollToElement}>
      <SEO />
      <Hero scrollToElement={scrollToElement} />
      <AboutUs scrollToElement={scrollToElement} />
      <Services />
      <Projects />
      <Contact />
      <Mastbottom scrollToElement={scrollToElement} />
    </Layout>
  );
}

export default IndexPage;
