import React from 'react';
import PropTypes from 'prop-types';
import './assets/global.scss';
import Header from './components/Header';
import Footer from './components/Footer';

function Layout({ scrollToElement, children }) {
  return (
    <>
      <Header scrollToElement={scrollToElement} />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  scrollToElement: PropTypes.func.isRequired
};

export default Layout;
