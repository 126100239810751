import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HeroContainer, OurButton, TitleText } from './Shared';
import MastheadPicture from '../assets/bg-masthead.jpg';

function Hero({ scrollToElement }) {
  return (
    <HeroContainer id="top" backgroundImage={MastheadPicture}>
      <Container className="h-100 d-flex align-items-end">
        <Row id="masthead-row" className="text-center mb-5" style={{ width: 'inherit' }}>
          <Col lg={12}>
            <TitleText className="text-white text-left">we</TitleText>
            <TitleText className="text-white text-left">are your</TitleText>
            <TitleText className="text-left text-secondary">
              <span className="text-white">AI </span>
              developers
            </TitleText>
            <TitleText className="text-left text-secondary">
              <span className="text-white">from </span>
              Switzerland
            </TitleText>
          </Col>

          <Col lg={12}>
            <OurButton size="lg" onClick={() => scrollToElement('about')} className="btn-secondary">
              More infos
            </OurButton>
          </Col>
        </Row>
      </Container>
    </HeroContainer>
  );
}

Hero.propTypes = {
  scrollToElement: PropTypes.func.isRequired
};

export default Hero;
