import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import { Divider, OurButton } from './Shared';
import Iurisprudentia from '../assets/iurisprudentia_Merriweather.png';

const ProjectsContainer = styled.section`
    padding: 8rem 0;
    background-color: rgb(157, 25, 63);
`;

function Projects() {
  return (
    <ProjectsContainer id="about">
      <Container>
        <Row className="justify-content-center">
          <Col lg={11} className="text-center">
            <h2 className="text-white mt-0" style={{ fontSize: '2.5rem' }}>Our latest project</h2>
            <Divider className="my-4" />
            <p id="about-us-text" className="text-white mb-5" style={{ fontSize: '1.3rem' }}>
              Unser letztes Projekt IURISPRUDENTIA entstand in Zusammenarbeit mit dem Lehrstuhl von Prof. Dr. iur Walter Boente der Universität Zürich. Ziel dieses Projekts ist es, historische rechtswissenschaftliche, meist nur handschriftlich verfügbare, Texte digital verfügbar zu machen. Mittels Künstlicher Intelligenz (Texterkennung) wurden bereits mehrere hunderttausend Texte maschinell transkribiert und digital, insbesondere der Forschung, verfügbar gemacht.
            </p>
            <a
              href="https://rwi.app/iurisprudentia/de"
              target="_blank"
              style={{ textDecoration: 'none' }}
              rel="noreferrer"
            >
              <img
                src={Iurisprudentia}
                alt="..."
                className="img-fluid"
                style={{ backgroundColor: 'transparent', border: 'none', maxWidth: '40%' }}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </ProjectsContainer>
  );
}

export default Projects;

/*
            <OurButton variant="light" size="lg" href="https://rwi.app/iurisprudentia/de" target="_blank" />
*/
