import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider, OurButton } from './Shared';

const AboutContainer = styled.section`
    padding: 8rem 0;
    background-color: rgb(157, 25, 63);
`;

function AboutUs({ scrollToElement }) {
  return (
    <AboutContainer id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11 text-center">
            <h2 className="text-white mt-0" style={{ fontSize: '2.5rem' }}>Who are we?</h2>
            <Divider className="my-4" />
            <p id="about-us-text" className="text-white mb-5" style={{ fontSize: '1.3rem' }}>
              Wir realisieren Ihre Idee,
              angefangen vom Design bis hin zur funktionierenden Webseite
              und/oder App. Wir sind ein junges, motiviertes Unternehmen, was sich vor allem
              auf alle Formen der Künstlichen Intelligenz spezialisiert hat und bereits Start-Up Erfahrungen
              sammeln konnte. Wir freuen uns auf neue Herausforderungen in der Zukunft und
              auf eine Zusammenarbeit mit Ihnen.
            </p>
            <OurButton variant="light" size="lg" onClick={() => scrollToElement('services')}>
              Know How
            </OurButton>
          </div>
        </div>
      </div>
    </AboutContainer>
  );
}

AboutUs.propTypes = {
  scrollToElement: PropTypes.func.isRequired
};

export default AboutUs;
