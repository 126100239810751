import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Navbar, Nav, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import device from '../util/Devices';

const NavbarContainer = styled(Navbar)`
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    @media ${device.lg} {
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
        ${(props) => props.scrolled && `
            -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            background-color: #fff;
        `}
    }

`;

const NavbarBrand = styled(Navbar.Brand)`
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #212529;
    @media ${device.lg} {
        color: rgba(255, 255, 255, 0.7) !important;
        &:hover {
            color: #fff;
        }
        ${(props) => props.scrolled && `
            color: rgb(157, 25, 63) !important;
            &:hover {
                color: black !important;
            }
        `}
    }
`;

const NavLink = styled(Nav.Link)`
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 0.9rem;
    &:hover, &:active {
        color: rgb(157, 25, 63) !important;
    }
    @media ${device.lg} {
        color: rgba(255, 255, 255, 0.7) !important;
        padding: 0 1rem !important;
        &:hover {
            color: #fff !important;
        }
        ${(props) => props.scrolled && `
            color: #212529 !important;
            &:hover {
                color: rgb(157, 25, 63) !important;
            }
        `}
        ${(props) => props.last && `
            padding-right: 0 !important;
        `}
    }
`;

function Header({ scrollToElement }) {
  const [scrolled, setScrolled] = useState(false);

  function fillHeader() {
    if (window.pageYOffset > 100) setScrolled(true);
    else setScrolled(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', fillHeader);
    return () => window.removeEventListener('scroll', fillHeader);
  }, [fillHeader]);

  return (
    <NavbarContainer scrolled={scrolled} expand="lg" variant="light" fixed="top" className="py-3">
      <Container>
        <NavbarBrand scrolled={scrolled} onClick={() => scrollToElement('top')}>mintqore</NavbarBrand>
        <Navbar.Toggle
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarResponsive">
          <ul className="navbar-nav ml-auto my-2 my-lg-0">
            <Nav.Item>
              <NavLink scrolled={scrolled} onClick={() => scrollToElement('about')}>about us</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink scrolled={scrolled} onClick={() => scrollToElement('services')}>services</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink last="true" scrolled={scrolled} onClick={() => scrollToElement('contact')}>contact</NavLink>
            </Nav.Item>
          </ul>
        </Navbar.Collapse>
      </Container>
    </NavbarContainer>
  );
}

Header.propTypes = {
  scrollToElement: PropTypes.func.isRequired
};

export default Header;
