import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import device from '../util/Devices';

export const Divider = styled.hr`
    border-color: #e2e6ea;
    max-width: 8rem;
    border-width: 0.2rem;
`;

export const BrandIcon = styled(FontAwesomeIcon)`
    color: rgb(157, 25, 63) !important;
    margin-bottom: 1.5rem !important;
    padding-right: ${({ left }) => (left ? '0.5rem' : '0.4rem')};
    padding-left: ${({ right }) => (right ? '0.5rem' : '')};
    ${({ react }) => react && `
        -webkit-animation: 6s infinite linear;
        animation: 6s infinite linear;
    `}
`;

export const HeroContainer = styled.header`
    padding-top: 10rem;
    padding-bottom: calc(10rem - 72px);
    background: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    @media (min-width: 992px) {
        height: 100vh;
        min-height: 40rem;
        padding-top: 72px;
        padding-bottom: 0;
    }
`;

export const OurButton = styled(Button)`
    padding: 1.25rem 2.25rem !important;
    font-size: 0.85rem !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    border: none !important;
    border-radius: 10rem !important;
`;

export const TitleText = styled.h1`
    font-size: 3.2rem;
    @media ${device.sm} {
        font-size: 4.3rem;
    }
    @media ${device.xl} {
        font-size: 5.5rem;
    }
`;
