import React from 'react';
import {
  faReact, faNode, faHtml5, faCss3, faApple, faAndroid, faPython, faAws
} from '@fortawesome/free-brands-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import { Divider, BrandIcon } from './Shared';

function Services() {
  return (
    <section style={{ padding: '8rem 0' }} id="services">
      <Container>
        <h2 className="text-center mt-0" style={{ fontSize: '2.5rem' }}>What can we do for you?</h2>
        <Divider className="my-4" style={{ borderColor: 'rgb(157, 25, 63)' }} />
        <Row>

          <Col lg={4} md={6} className="text-center">
            <div className="mt-5">
              <BrandIcon icon={faBrain} size="4x" />
              <h3 className="h4 mb-2">Artificial Intelligence</h3>
              <p className="text-muted mb-0">
                {' '}
                Von klassischer Datenanalyse über Machine Learning bis zu Deep Learning helfen wir Ihnen mit der passenden Lösung bei der Bewältigung Ihrer Herausforderungen.
              </p>
            </div>
          </Col>

          <Col lg={4} md={6} className="text-center">
            <div className="mt-5">
              <BrandIcon icon={faReact} size="4x" spin />
              <h3 className="h4 mb-2">React & React Native</h3>
              <p className="text-muted mb-0">
                {' '}
                Mit React lassen sich
                Web-Applikationen modular und nach den neusten Standards
                aufbauen, während mit React-Native iOS & Android Apps
                entwickelt werden können.
              </p>
            </div>
          </Col>

          <Col lg={4} md={6} className="text-center">
            <div className="mt-5">
              <BrandIcon icon={faNode} size="4x" />
              <h3 className="h4 mb-2">Node.js</h3>
              <p className="text-muted mb-0">
                Brauchen Sie ein Backend oder
                eine API? Mit Node.js bauen wir Ihnen ein stabiles
                und leistungsfähiges Backend in JavaScript auf.
              </p>
            </div>
          </Col>

        </Row>

        <Row>

          <Col lg={4} md={6} className="text-center">
            <div className="mt-5">
              <BrandIcon icon={faAndroid} size="4x" left="true" />
              <BrandIcon icon={faApple} size="4x" right="true" />
              <h3 className="h4 mb-2">Android & iOS</h3>
              <p className="text-muted mb-0">
                In Java oder Swift schreiben wir
                Ihnen eine Android oder iOS App oder verbessern eine bereits
                bestehende App und fügen ihr neue Features hinzu.
              </p>
            </div>
          </Col>

          <Col lg={4} md={6} className="text-center">
            <div className="mt-5">
              <BrandIcon icon={faPython} size="4x" />
              <h3 className="h4 mb-2">Python</h3>
              <p className="text-muted mb-0">
                Haben Sie ein datenlastiges Projekt oder
                fehlen Ihrer App die nötigen Daten? Mit Python und unseren
                Web-Scraping Kenntnissen lässt sich das lösen.
              </p>
            </div>
          </Col>

          <Col lg={4} md={6} className="text-center">
            <div className="mt-5">
              <BrandIcon icon={faHtml5} size="4x" left="true" />
              <BrandIcon icon={faCss3} size="4x" right="true" />
              <h3 className="h4 mb-2">HTML/CSS</h3>
              <p className="text-muted mb-0">
                Ihre Web-Applikation wird
                nach den neusten HTML5 und CSS 3 Standards geschrieben
                und mit allen Browsern und Screen-Grössen kompatibel gemacht.
              </p>
            </div>
          </Col>

        </Row>

        <Row className="justify-content-center">

          <Col lg={4} md={6} className="text-center">
            <div className="mt-5">
              <BrandIcon icon={faAws} size="4x" />
              <h3 className="h4 mb-2">Cloud Services</h3>
              <p className="text-muted mb-0">
                Mit den neusten Cloud-Technologien von
                Amazon Web Services lässt sich Ihre Webseite oder API
                kostengünstig, sicher und Ihren Bedürfnissen gerecht hosten.
              </p>
            </div>
          </Col>

        </Row>

      </Container>
    </section>
  );
}

export default Services;
