import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin, faGithub, faTwitter, faFacebook
} from '@fortawesome/free-brands-svg-icons';

const FooterContainer = styled.footer`
    background-color: rgb(157, 25, 63);
    color: white;
    padding-top: 3rem !important;
    padding-bottom: 2rem !important;
    ul {
        margin-top: 2rem; 
        padding-left: 0;
    }
`;

const SocialIcon = styled.a`
    padding: 15px;
    font-size: 25px;
    width: 70px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    margin: 5px 2px;
`;

const Copyright = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: inherit;
    margin-bottom: 1rem;
`;

function Footer() {
  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col>
            <h5 className="title">HEAD OFFICE</h5>
            <ul>
              <li className="list-unstyled">mintqore GmbH</li>
              <li className="list-unstyled">Universitätstrasse 47</li>
              <li className="list-unstyled">8006 Zürich</li>
              <li className="list-unstyled">Switzerland</li>
            </ul>
          </Col>

          <Col>
            <h5 className="title">CONTACT</h5>
            <ul>
              <li className="list-unstyled">info@mintqore.com</li>
              {/* <li className="list-unstyled">+41 71 111 11 11</li> */}
            </ul>
          </Col>

          <Col>
            <Container fluid>
              <Row className="justify-content-end">
                <div>
                  <SocialIcon
                    className="text-white"
                    href="https://github.com/"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </SocialIcon>
                  <SocialIcon
                    className="text-white"
                    href="https://linkedin.com/"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </SocialIcon>
                  <SocialIcon
                    className="text-white"
                    href="https://twitter.com/"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </SocialIcon>
                  <SocialIcon
                    className="text-white"
                    href="https://facebook.com/"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </SocialIcon>
                </div>
              </Row>
            </Container>
            <Copyright className="text-right">
              copyright &copy;
              {' '}
              {new Date().getFullYear()}
              {' '}
              - mintqore.com
            </Copyright>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
